<template>
  <div class="left-space-desktop">
  <div class="page-content w-100">
    <!-- start heading -->
    <div class="row">
      <div class="col-sm-12">
        <p class="text-center page-heading">Profile and Settings</p>
      </div>

    </div>
    <!-- end heading -->
    <div class="row mt-2">
      <div class="col-sm-12 usersStyle">

        
        <!-- end accordion -->

        <nav class="d-none d-sm-block d-md-block d-lg-block">
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <button class="nav-link active" id="nav-myinfo-tab" data-bs-toggle="tab" data-bs-target="#nav-myinfo"
              type="button" role="tab" aria-controls="nav-myinfo" aria-selected="true">About me</button>

            <button class="nav-link" id="nav-myfamily-tab" data-bs-toggle="tab" data-bs-target="#nav-myfamily"
              type="button" role="tab" aria-controls="nav-myfamily" aria-selected="false">Family</button>

            <!-- <button class="nav-link" id="nav-advancedsettings-tab" v-show="!show" @click="navbar"
              data-bs-target="#nav-advancedsettings" type="button" role="tab" aria-controls="nav-advancedsettings"
              aria-selected="false">Advanced settings</button> -->

            <button class="nav-link" id="nav-topic-tab"  data-bs-toggle="tab" data-bs-target="#nav-topic"
              type="button" role="tab" aria-controls="nav-topic" aria-selected="false">Topic
              of interest</button>

            <button class="nav-link" id="nav-communitygroups-tab"  data-bs-toggle="tab"
              data-bs-target="#nav-communitygroups" type="button" role="tab" aria-controls="nav-communitygroups"
              aria-selected="false">Community & Groups</button>

            <!-- <button class="nav-link" id="nav-settings-tab" v-show="show" data-bs-toggle="tab"
              data-bs-target="#nav-settings" type="button" role="tab" aria-controls="nav-settings"
              aria-selected="true">Settings</button> -->
          </div>
        </nav>
        <div class="text-border d-none d-sm-block d-md-block d-lg-block "></div>
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active" id="nav-myinfo" role="tabpanel" aria-labelledby="nav-myinfo-tab">
            <MyInfo></MyInfo>
          </div>
          <div class="tab-pane fade" id="nav-myfamily" role="tabpanel" aria-labelledby="nav-myfamily-tab">
            <MyFamily></MyFamily>
          </div>
          <div class="tab-pane fade" id="nav-topic" role="tabpanel" aria-labelledby="nav-topic-tab">
            <TopicInterest></TopicInterest>
          </div>
          <div class="tab-pane fade" id="nav-communitygroups" role="tabpanel" aria-labelledby="nav-communitygroups-tab">
            <GroupsUser></GroupsUser>
          </div>
          <div class="tab-pane fade" id="nav-settings" role="tabpanel" aria-labelledby="nav-settings-tab">
            <Settings></Settings>
          </div>
        </div>

      </div>
    </div>
  </div>
  </div>
</template>
<script>
import MyInfo from './my-info/my-info.vue'
import MyFamily from './my-family/my-family.vue'
import TopicInterest from './topic-interest/topic-interest.vue'
import GroupsUser from './groups-user/groups-user.vue'
import Settings from './settings/settings.vue'
export default {
  components: {
    MyInfo,
    MyFamily,
    TopicInterest,
    GroupsUser,
    Settings
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    navbar: function () {
      this.show = !this.show;
    }
  }
}

</script>
<style>
.nav.nav-tabs {
  padding-top: 15px;
}

.col-sm-12.usersStyle {
  margin: 0px 0px 0px 10px;
  background-color: #ffff;
  border-radius: 10px;
  padding-bottom: 15px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%), 0 1px 2px 0 rgb(0 0 0 / 12%);

}

.col-sm-12.mx-auto.div-space {
  margin-bottom: 30px;
}

.left-space-desktop {
    margin-left: 20px;
  }

.text-border {
  background-color: #00979e;
  height: 4px;
  margin-bottom: 20px;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.nav-tabs .nav-link.active {
  color: white;
  background-color: #00979e;
  border-color: #dee2e6 #dee2e6 #fff;
}

@media screen and (max-width:991px) {
  .col-sm-12.usersStyle{
  margin:auto;
  background-color: #ffff;
  border-radius: none;
  padding-bottom: 15px;
  box-shadow:none;
}
.nav.nav-tabs{
  padding-top: 0px;
}
.left-space-desktop {
    margin-left: 0px;
  }
}
</style>
